<section id="slider" >
  <carousel [isAnimated]="true">
    <slide style="background-image: url('/assets/uploads/cache/slider_lg/slider/nowe-pozycje-w-menu.jpg');">
      <!-- <img src="" alt="first slide" style="display: block; width: 100%;"> -->
      <div class="carousel-caption ">
        <p class="lead">Zamawiaj z dowozem codziennie!</p>
        <p>Zamówienia przyjmujemy do godziny 21:30</p>
      </div>
    </slide>
    <slide style="background-image: url('/assets/images/banners/16lat.jpg');">
      <!-- <img src="" alt="second slide" style="display: block; width: 100%;"> -->
      <div class="carousel-caption ">
        <p class="lead">{{ lat }} lat na rynku!</p>
        <p>Zamów, sprawdz nasze wieloletnie doświadczenie.</p>
      </div>
    </slide>
  </carousel>
</section>

<section id="menu" class="py-3">

  <app-menu></app-menu>

</section>

<!-- <section id="slider" class="carousel slide fade" data-ride="carousel"> -->
  <!-- <ol class="carousel-indicators">
                      <li data-target="#slider" data-slide-to="0" class="active"></li>
              </ol> -->
  <!-- <div class="carousel-inner" role="listbox">
                      <div class="item active" style="background-image: url('/assets/uploads/cache/slider_lg/slider/nowe-pozycje-w-menu.jpg');"> -->
              <!-- <div class="fill parallax jsImage"
                  data-lgimage="/uploads/cache/slider_lg/slider/nowe-pozycje-w-menu.jpg"
                  data-mdimage="/uploads/cache/slider_md/slider/nowe-pozycje-w-menu.jpg"
                  data-smimage="/uploads/cache/slider_sm/slider/nowe-pozycje-w-menu.jpg"
                  data-xsimage="/uploads/cache/slider_xs/slider/nowe-pozycje-w-menu.jpg"
              ></div> -->
              <!-- <div class="carousel-caption">
                  <img class="hidden-sm hidden-xs" src="/assets/images/logo.png" alt="Pizzeria Salamandra">
                  <p class="lead">Zamawiaj z dowozem codziennie!</p>
                  <p>Zamówienia przyjmujemy do godziny 21:30</p>
                                              <a href="#dowoz" class="btn btn-primary btn-lg pull-right">zobacz więcej...</a>
                                      </div>
          </div>
              </div> -->
  <!-- <a class="left carousel-control" href="#slider" role="button" data-slide="prev">
      <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
  </a>
  <a class="right carousel-control" href="#slider" role="button" data-slide="next">
      <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
  </a> -->
<!-- </section> -->

<!--<section id="promocje">-->
<!--<div class="container">-->
<!--  <div class="row">-->
<!--      <div class="col-12">-->
<!--          <h2>Promocje</h2>-->
<!--      </div>-->
<!--  </div>-->
<!--  <div class="row">-->
<!--      <div class="col-sm-6 text-center">-->
<!--          <img alt="5 pizza gratis" src="/assets/images/5pizza.png" class="img-responsive center-block">-->

<!--          <h4>Przy zamówieniu 4 pizz, piąta <strong>gratis</strong></h4>-->
<!--      </div>-->
<!--&lt;!&ndash;      <div class="col-sm-6 col-md-4 text-center">&ndash;&gt;-->
<!--&lt;!&ndash;          <img alt="zamówienie powyżej 100zł  - napój gratis" src="/assets/images/sok.png" class="img-responsive center-block">&ndash;&gt;-->
<!--&lt;!&ndash;          <h4>Przy zamówieniu powyżej 100 zł - napój <strong>gratis</strong></h4>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->

<!--      <div class="col-sm-6  text-center">-->
<!--          <img alt="podwójne ciasto na pizzy" src="/assets/images/ciasto.png" class="img-responsive center-block">-->
<!--          <h4>Na życzenie klienta cienkie lub podwójne ciasto <strong>gratis</strong></h4>-->
<!--      </div>-->
<!--      <div class="col-12 pt-3">-->
<!--          <p class="text-right"><sup>*</sup> Promocje nie łączą się</p>-->
<!--      </div>-->
<!--  </div>-->
<!--</div>-->
<!--</section>-->
<div id="dojazd" class="parallax">
</div>
<section id="dowoz">
<div class="container">
  <div class="col-12">
      <h2>{{ 'Dowóz gratis:' | uppercase }}</h2>

<!--      <p class="lead">Oferujemy bezpłatny dowóz zamówień przy zakupie:</p>-->


      <p class="lead">
          powyżej <strong>40 zł</strong>: Skórzewo, os. Kwiatowe
      </p>
      <p class="lead">
          powyżej <strong>50 zł</strong>: Dąbrowa, Zakrzewo,
      </p>
      <p class="lead">
          powyżej <strong>55 zł</strong>: os. Bajkowe, Ławica
      </p>
      <p class="lead">
          powyżej <strong>65 zł</strong>: Plewiska, Przeźmierowo
      </p>
      <p class="lead">
        powyżej <strong>80 zł</strong>: Dopiewiec, Gołuski, Palędzie
    </p>

    <p class="alert alert-danger">
      Informujemy, że od dnia 20.11 do odwołania dostawa do Dąbrówki nie będzie możliwa ze względu na utrudnienia w ruchu drogowym związane z przebudową ulicy Poznańskiej.
      <br>
      <strong>Zachęcamy do odbioru osobistego w lokalu.</strong>
    </p>

      <p class="lead"><strong>Zamówienia przyjmujemy do godziny 21.30</strong></p>
  </div>
</div>
</section>
<div class="parallax" id="tel">
<p>Zadzwoń! <span>605 850 795</span></p>
</div>
<div id="kontakt"></div>
